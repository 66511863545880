/*
 *
 * CheckoutStep1Page actions
 *
 */

import { action } from 'typesafe-actions';
import {} from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const removeAllProduct = (data: any) => action(ActionTypes.REMOVE_ALL_PRODUCT, data);
