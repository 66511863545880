import * as React from 'react';
import styled from 'styled-components';
import SVGIcon from '../SVGIcon/SVGIcon';
import supplerMark from './suppler-mark.svg';

const SubplierMark = styled(SVGIcon)<any>`
  display: inline-block;
  path {
    fill: #2e904e;
  }
  &.unregistered {
    path {
      fill: #dce0e9;
    }
  }
`;

export default function (props: any) {
  return <SubplierMark renderAsImg={false} {...props} src={supplerMark} />;
}
